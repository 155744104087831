import { Injectable } from "@angular/core";
import mixpanel from "mixpanel-browser";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" }) export class MixpanelService {

  init(email: string): void {
    if(environment.production === true){
      mixpanel.init("d61185e5a3d36cd7fb353cfcb0230f09");
      localStorage.setItem('mixpanelPublicInit', 'true');
    } else {
      localStorage.setItem('mixpanelPublicInit', 'false');
    }
  }

  setParams(params: any): void {
    let prms = {...params}
    if(params.pk) prms.email = atob(params.pk)
    this.track("Landing", prms);  
  }

  track(id: string, action: any = {}, embedded: boolean = false): void {
    if(localStorage.getItem('mixpanelPublicInit') === 'true'){
      mixpanel.time_event(id);
      mixpanel.track(id, action);  
    } else if(!embedded) {
      console.log(id);
      console.log(action);
    }
  }

}
