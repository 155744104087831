<div class="lottie" *ngIf="dataService.loader && dataService.params.origin !== 'Embedded'">
  <ng-lottie class="lot" [options]="lottieOptions"></ng-lottie>
</div>

<div class="container" *ngIf="!dataService.loader && dataService.params.origin !== 'Embedded'">
  <div class="main">
    <div class="header">
      <a target="_blank" matTooltip="{{ 'goToPage' | translate }}" href="https://seenka.com/">
        <img id="logoSeenka" src="assets/Seenka.svg">
      </a>

      <div class="imgCont">
        <img *ngIf="dataService.clip.workspace_icon" [src]="dataService.clip.workspace_icon"
          (error)="iconError($event)">
      </div>
    </div>

    <div class="error" *ngIf="dataService.error" [ngSwitch]="dataService.error">
      <div class="errorMessage" *ngSwitchCase="'asset_not_available'">
        <h1>{{ 'assetNotAvailable' | translate }}</h1>
        <p>{{ 'assetNotAvailableHelp' | translate }}</p>
      </div>

      <div class="errorMessage oops" *ngSwitchCase="'invalid_url'">
        <h1>{{ 'invalidURL' | translate }}</h1>
      </div>

      <div class="errorMessage oops" *ngSwitchDefault>
        <h1>{{ 'serverError' | translate }}</h1>
        <p>{{ 'serverErrorHelp1' | translate }}<br>{{ 'serverErrorHelp2' | translate }}</p>
      </div>
    </div>

    <div class="imageCont" *ngIf="currentFormat === 'image'">
      <img class="image" [src]="dataService.clip.clip_url">
    </div>

    <app-audio *ngIf="currentFormat === 'audio'"></app-audio>

    <app-vjs-player class="notEmbedded" *ngIf="currentFormat === 'video'" [videoOptions]="videoService.videoOptions">
    </app-vjs-player>

    <div class="titleBox">
      <h3 class="title" *ngIf="!dataService.error">{{ dataService.clip.name }}</h3>

      <div class="buttons">
        <mat-icon class="button" *ngIf="!dataService.error"
          (click)="colorService.colorTheme === 'dark-theme' ? setLightTheme() : setDarkTheme()"
          [matTooltip]="getTooltip()" matTooltipShowDelay="500">{{ colorService.colorTheme === 'dark-theme' ?
          'light_mode' : 'dark_mode' }}
        </mat-icon>
        <mat-icon class="button" *ngIf="!dataService.error">
          <span *ngIf="!videoService.downloading.loading" (click)="currentExtension === 'm3u8'
              ? videoService.convertToMp4()
              : videoService.download(dataService.clip.clip_url)" matTooltip="{{ 'download' | translate }}">download
          </span>
          <mat-progress-spinner *ngIf="videoService.downloading.loading"
            [mode]="videoService.downloading.value > 0 ? 'determinate' : 'indeterminate'"
            [value]="videoService.downloading.value" [diameter]="20">
          </mat-progress-spinner>
        </mat-icon>
        <a target="_blank" [href]="dataService.clip.asset_destination"
          *ngIf="(dataService.clip.asset_destination && dataService.clip.asset_destination !== '')">
          <mat-icon class="button">open_in_new</mat-icon>
        </a>
      </div>
    </div>
    <div class="entitiesBox" *ngIf="!dataService.error">
      <span class="entities" *ngFor="let entitie of getEntities()">{{ entitie }}</span>
    </div>

    <div class="adds" *ngIf="!dataService.error">
      <div class="add" *ngIf="dataService.clip?.media">{{ dataService.clip.media }}</div>
      <div class="add" *ngIf="dataService.clip?.program"><span>,</span> {{ dataService.clip.program }}</div>
      <div class="add" *ngIf="dataService.clip?.brand">{{ dataService.clip.brand }}</div>
      <div class="add time" *ngIf="dataService.clip.airing_data?.start_time">{{ dataService.clip.airing_data.start_time
        | date: 'dd/MM/yy, hh:mm:ss a' }}</div>
    </div>
  </div>
</div>

<mat-spinner *ngIf="dataService.loader && dataService.params.origin === 'Embedded'"></mat-spinner>

<div *ngIf="!dataService.loader && dataService.params.origin === 'Embedded'">
  <app-vjs-player class="videoEmbedded" *ngIf="currentFormat === 'video'" [videoOptions]="videoService.videoOptions">
  </app-vjs-player>

  <div class="imageCont" *ngIf="currentFormat === 'image'">
    <img class="image" [src]="dataService.clip.clip_url">
  </div>

  <app-audio class="audioEmbedded" *ngIf="currentFormat === 'audio'"></app-audio>

  <div class="error" *ngIf="dataService.error" [ngSwitch]="dataService.error">
    <div class="errorMessage" *ngSwitchCase="'asset_not_available'">
      <h1>{{ 'assetNotAvailable' | translate }}</h1>
      <p>{{ 'assetNotAvailableHelp' | translate }}</p>
    </div>

    <div class="errorMessage oops" *ngSwitchCase="'invalid_url'">
      <h1>{{ 'invalidURL' | translate }}</h1>
    </div>

    <div class="errorMessage oops" *ngSwitchDefault>
      <h1>{{ 'serverError' | translate }}</h1>
      <p>{{ 'serverErrorHelp1' | translate }}<br>{{ 'serverErrorHelp2' | translate }}</p>
    </div>
  </div>
</div>