import { Component, OnInit } from '@angular/core';
import { Clip } from '../models/clip';
import { DataService } from '../services/data.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { LanguageService } from '../services/language.service';
import { VideoService } from '../services/video.service';
import { ColorService } from '../services/color.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  lottieOptions: AnimationOptions = { path: '/assets/lottie/loaderSeenka.json' };
  animationItem = {} as AnimationItem;
  animationComplete = false;

  formats = {
    video: ['mp4'],
    audio: ['mp3'],
    image: ['jpg', 'png']
  } as any;

  currentFormat: string = "";
  currentExtension: string = "";

  constructor(
    private translate: TranslateService,
    public colorService: ColorService,
    public videoService: VideoService,
    public dataService: DataService,
    public languageService: LanguageService,
  ){}

  ngOnInit(): void {
    this.dataService.getData();
    this.dataService.getClip().subscribe((clip: Clip) => {
      this.setFormat(clip);
    });
  }

  setFormat(clip: Clip){
    if (clip.clip_url.includes('m3u8')) {
      this.currentExtension = 'm3u8';
      if (clip.format === 'mp3') {
        this.currentFormat = 'audio';
      } else if (clip.format === 'ts') {
        this.currentFormat = 'video';
      }
    } else {
      this.currentFormat = Object.keys(this.formats).find(key =>
        this.formats[key].some((fk: string) => {
          this.currentExtension = fk
          return clip.clip_url?.toLowerCase().includes(fk) || '';
        })
      ) as any;
    }

    this.dataService.setInit();
    if(this.currentFormat === 'video')
      this.videoService.setVideoOptions(clip.clip_url, this.currentExtension);
  }

  refresh(){
    location.reload();
  }

  setLightTheme(){
    this.colorService.setLightTheme()
  }

  setDarkTheme(){
    this.colorService.setDarkTheme()
  }

  getTooltip(){
    let mode = this.colorService.colorTheme === 'dark-theme' ? 'lightMode' : 'darkMode';
    return this.translate.instant('settings.' + mode);
  }

  getEntities(){
    return this.dataService.clip.airing_data?.entities?.split(',');
  }

  iconError(event: any){
    let el = event.target as HTMLElement;
    const parent = el.parentNode;
    if(!parent) return
    parent.removeChild(el);

    const newElement = document.createElement('div');
    newElement.classList.add('material-icons');
    newElement.textContent = 'work';

    let firstChild = parent.firstChild
    if(firstChild) parent.insertBefore(newElement, parent.firstChild);
    else parent.appendChild(newElement);
  }

}
