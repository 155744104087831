<video #target
  class="video-js vjs-big-play-centered vjs-16-9"
  data-setup="{'fluid': true}"
  [class.notEmbedded]="dataService.params.origin !== 'Embedded'"
  [poster]="dataService.clip.thumb_url"
  [currentTime]="dataService.clip.offset"
  playsinline
  data-setup='{ "playbackRates": [0.5, 0.75, 1, 1.5, 1.75, 2] }'
  preload="auto">
</video>

