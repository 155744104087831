import { Component, HostListener, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { DataService } from '../services/data.service';
import Hls from 'hls.js';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnInit {
  play = false;
  isMuted = false;
  currentTime: string = '0:00';
  duration: string = '0:00';
  sliderMax: number = 100;
  bufferedAmount: number = 0;
  seekableAmount: number = 0;
  sliderValue: number = 0;
  volumeValue: number = 80;
  volAux: number = 80;
  audio: undefined | HTMLAudioElement;

  animationItem = {} as AnimationItem;
  lottieOptions: AnimationOptions = {
    path: '/assets/lottie/playPause.json',
    renderer: 'svg',
    loop: false,
    autoplay: false,
  };

  @HostListener('window:keydown', ['$event']) handleKeyDown(
    event: KeyboardEvent
  ) {
    let dir, vol: number | undefined;
    switch (event.code) {
      case 'ArrowLeft':
        dir = -5;
        break;
      case 'ArrowRight':
        dir = 5;
        break;
      case 'ArrowDown':
        vol = -10;
        break;
      case 'ArrowUp':
        vol = 10;
        break;
      case 'Space':
        this.togglePlay();
        break;
    }
    if (dir) this.audio!.currentTime = this.audio!.currentTime + dir;
    if (vol) {
      let newVol = this.audio!.volume * 100 + vol;
      newVol = newVol < 0 ? 0 : newVol > 100 ? 100 : newVol;
      this.setVolSlider(newVol.toString());
    }
  }

  constructor(public dataService: DataService) {}

  ngOnInit(): void {
    this.audio = document.getElementById('player') as HTMLAudioElement;
    if (this.dataService.clip.clip_url.includes('m3u8')) {
      let hls = new Hls();
      hls.loadSource(this.dataService.clip.clip_url);
      hls.attachMedia(this.audio! as any);
    } else {
      this.audio!.src = this.dataService.clip.clip_url;
    }
    setTimeout(() => {
      let offset = this.dataService.clip.offset || 0;
      this.setSeekSlider(offset.toString() || '0');
      this.customTimeSlider(offset);
    }, 1000);
  }

  customTimeSlider(offset: number) {
    let elem = document.getElementById('time-slider') as HTMLElement;
    elem.style.setProperty('--offsetStart', (offset / this.sliderMax) * 100 + '%');
    elem.style.setProperty('--offsetEnd', (this.dataService.clip.duration / this.sliderMax) * 100 + '%');
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.animationItem.setSegment(0, 0);
  }

  togglePlay() {
    if (this.play) {
      this.audio!.pause();
      this.animationItem.playSegments([65, 100], true);
    }
    if (!this.play) {
      this.audio!.play();
      this.animationItem.playSegments([0, 50], true);
    }
    this.play = !this.play;
  }

  setSeekSlider(value: string) {
    let range = parseInt(value);
    let audioPlayerContainer = document.getElementById('audio-player-container') as HTMLElement;
    audioPlayerContainer.style.setProperty('--seek-before-width', (range / this.sliderMax) * 100 + '%');
    this.currentTime = this.calculateTime(range);
    let audio = document.querySelector('audio') as HTMLAudioElement;
    audio.currentTime = range;
  }

  setVolSlider(range: string) {
    let audioPlayerContainer = document.getElementById('audio-player-container') as HTMLElement;
    audioPlayerContainer.style.setProperty('--volume-before-width', range + '%');
    this.volumeValue = parseInt(range);
    this.isMuted = false;
    this.audio!.volume = parseInt(range) / 100;
  }

  mute(volume: string) {
    this.volAux = parseInt(volume);
    this.setVolSlider('0');
    this.isMuted = !this.isMuted;
  }

  audioLoaded() {
    this.sliderMax = Math.floor(this.audio!.duration);
    this.duration = this.calculateTime(this.audio!.duration);
    this.setBuffer(this.audio!);
    this.setVolSlider(this.volumeValue.toString());
  }

  setBuffer(audio: HTMLAudioElement) {
    this.bufferedAmount = audio.buffered.end(audio.buffered.length - 1);
    this.seekableAmount = audio.buffered.end(audio.seekable.length - 1);
  }

  calculateTime(secs: number) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }

  audioProgress() {
    this.bufferedAmount = Math.floor(this.audio!.buffered.end(this.audio!.buffered.length - 1));
    let audioPlayerContainer: any = document.getElementById('audio-player-container');
    audioPlayerContainer.style.setProperty('--buffered-width', `${(this.bufferedAmount / this.sliderMax) * 100}%`);
  }

  audioUpdate() {
    this.currentTime = this.calculateTime(this.audio!.currentTime);
    this.sliderValue = this.audio!.currentTime;
    let audioPlayerContainer: any = document.getElementById('audio-player-container');
    audioPlayerContainer.style.setProperty('--seek-before-width', (this.audio!.currentTime / this.sliderMax) * 100 + '%');
  }
}
