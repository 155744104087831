<div id="audio-player-container" [class.audioEmbedded]="dataService.params.origin === 'Embedded'">
  <p>Seenka technology</p>

  <button (click)="togglePlay()" id="play-icon">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="lottieOptions">
    </ng-lottie>
  </button>

  <audio
    (timeupdate)="audioUpdate()"
    (loadeddata)="audioLoaded()"
    (progress)="audioProgress()"
    preload="auto"
    id="player">
  </audio>

  <div class="inputs">
    <div class="above">
      <span class="time">{{ currentTime }}</span>
      <input
        type="range"
        #refSlider
        id="time-slider"
        (click)="setSeekSlider(refSlider.value)"
        [max]="sliderMax"
        [value]="sliderValue">
      <span class="time">{{ duration }}</span>
    </div>
    <div class="below">
      <span class="volume">{{ volumeValue }}</span>
      <input
        type="range"
        id="volume-slider"
        #refVolume
        (change)="setVolSlider(refVolume.value)"
        max="100"
        [value]="volumeValue"
      >
      <mat-icon class="mute" (click)="mute(refVolume.value)" *ngIf="!isMuted">volume_up</mat-icon>
      <mat-icon class="mute" (click)="setVolSlider(volAux.toString())" *ngIf="isMuted">volume_mute</mat-icon>
    </div>
  </div>
</div>
