import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs from 'video.js';
import { DataService } from '../services/data.service';
import { MixpanelService } from '../services/mixpanel.service';
@Component({
  selector: 'app-vjs-player',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef | undefined;
  @Input() videoOptions: any           // See options: https://videojs.com/guides/options
  player = {} as videojs.Player;

  @HostListener('window:keydown', ['$event']) handleKeyDown(event: KeyboardEvent) {
    let dir, vol: number | undefined;
    switch(event.code){
      case "ArrowLeft": dir = -5; break;
      case "ArrowRight": dir = 5; break;
      case "ArrowDown": vol = -0.1; break;
      case "ArrowUp": vol = 0.1; break;
      case "Space": this.togglePlay(); break;
    }
    if(dir){
      this.player.userActive(true);
      this.player.currentTime(this.player.currentTime() + dir);
    }
    if(vol){
      this.player.userActive(true);
      this.player.volume(this.player.volume() + vol);
    }
  }

  constructor(public dataService: DataService, private mixpanelService: MixpanelService){}

  ngOnInit(){
    this.player = videojs(this.target?.nativeElement, this.videoOptions, function onPlayerReady(){});
    this.player.ready(() => {
      if(this.dataService.params.origin === 'Embedded') this.player.play();
    });
    this.player.on('error', (err) => {
      this.reportToMixpanel(err.target.player.error_);
    });
  }

  ngOnDestroy() {
    if(this.player) this.player.dispose();
  }

  togglePlay(){
    this.player.paused() ? this.player.play() : this.player.pause();
  }

  reportToMixpanel(vjsError: any){
    this.mixpanelService.track('HTML Error', {
      type: "video",
      clip: this.dataService.clip,
      params: this.dataService.params,
      videojsError: vjsError
    });
  }

}
