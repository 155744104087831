import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";
import { SnackbarService } from '../snackbar/snackbar.service';
import { Clip } from '../models/clip';
import { QueryParams } from '../models/queryParams';
import { LanguageService } from './language.service';
import { MixpanelService } from './mixpanel.service';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root'}) export class DataService {
  private clip$ = new Subject<Clip>();
  getClip(): Observable<any> { return this.clip$.asObservable() };
  clip = {} as Clip;

  params = {} as QueryParams;
  loader: boolean | undefined;
  error: any = null;

  constructor(
    private mixpanelService: MixpanelService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private snackBar: SnackbarService,
    private route: ActivatedRoute,
    private http: HttpClient,
  ){}

  getData(){
    this.setInitParams();
    if(this.params.redirectTo){
      this.mixpanelService.setParams(this.params);
      window.location.href = this.params.redirectTo;
      return;
    }
    const url = this.setUrl();
    this.http.get(url).subscribe({
      next: (res: any) => {
        if (res.airing_data?.deleted_at) return this.setError('asset_not_available');
        if (res.redirect_url) return window.location.href = res.redirect_url;
        this.clip = res as Clip;
        this.clip$.next(res as Clip);
        if(this.params.type === 'airing') this.getOffset(this.clip);
        if(!this.clip.clip_url) return this.setError('No clip_url in the object');
        //console.log(this.clip)
      },
      error: (err) => this.setError(err)
    });
  };

  setInitParams(){
    this.loader = true;
    this.params = this.route.snapshot.queryParams as QueryParams;
    this.languageService.setTranslate(this.params.lang);
    this.mixpanelService.init(this.params.email);
    setTimeout(() => {
      if(this.loader) this.snackBar.advice(this.translateService.instant('loadingSlow'));
    }, 5000);
  }

  setUrl(){
    const wsId = this.params.workspace_id || '0';
    const newWsId = this.params.new_workspace_id;
    const baseUrl = environment.URL_WORKSPACES + 'landing/' + this.params.type + '/' + this.params.id + '/json';
    let url = newWsId ? baseUrl + '?new_workspace_id=' + newWsId : baseUrl + '?workspace_id=' + wsId;
    if (this.params.start_time && this.params.end_time) {
      url += '&start_time=' + this.params.start_time + '&end_time=' + this.params.end_time;
    }
    return url;
  }

  setError(err: any){
    this.mixpanelService.track('Data Error', err);
    if(err.error?.msg === "'clip_url'" || err.error?.status === 404 || err.status === 404)
      this.error = 'asset_not_available';
    else if(err.error?.includes('Not Found')) this.error = 'invalid_url';
    else this.error = 'error';
    this.setInit();
  }

  setInit(){
    this.loader = false;
    if(!this.error) this.snackBar.close();
    this.languageService.currentLang = this.translateService.currentLang;
  }

  getOffset(clip: any){
    let url = clip.clip_url.replace('/m3u8/', '/m3u8_offset/');
    this.http.get(url).subscribe((res: any) => {
      if(res) this.clip.offset = res.offset;
    });
  }
}
