import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' }) export class LanguageService {
  currentLang: string | undefined;

  constructor(private translate: TranslateService){}

  setTranslate(lang?: string){
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('es');
    if(lang){
      switch (lang) {
        case "es": this.translate.use('es'); break;
        case "en": this.translate.use('en'); break;
        default: this.translate.use('es'); break;
      }  
    } else {
      const browserLang = this.translate.getBrowserLang();
      this.translate.use(browserLang?.match(/en|es/) ? browserLang : 'es');  
    }
  }

  changeLang(){
    switch(this.translate.currentLang){
      case 'es': this.translate.use('en'), this.translate.currentLang = 'en'; break;
      case 'en': this.translate.use('es'), this.translate.currentLang = 'es'; break;
    }
  }

}
