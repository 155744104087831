import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";
import { SnackbarService } from '../snackbar/snackbar.service';
import { Clip } from '../models/clip';
import { QueryParams } from '../models/queryParams';
import { LanguageService } from './language.service';
import { MixpanelService } from './mixpanel.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' }) export class DataService {

  clip = {} as Clip;
  clip$: Subject<any> = new Subject();

  params = {} as QueryParams;
  loader: boolean | undefined;
  error: any = null;

  constructor(
    private mixpanelService: MixpanelService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private snackBar: SnackbarService,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) { }

  getData() {
    this.setInitParams();

    if (this.params.redirectTo) {
      this.mixpanelService.setParams(this.params);
      window.location.href = decodeURIComponent(this.params.redirectTo);
      return;
    }

    this.http.get(environment.URL_MEDIA_SERVICE + 'api/airings/' + this.params.type + '/' + this.params.id).subscribe({
      next: (res: any) => {
        if (res.airing_data?.deleted_at) return this.setError('not_found');
        if (res.redirect_url) return window.location.href = res.redirect_url;
        this.clip = res as Clip;
        if (this.clip.material_type === "Image") this.clip.clip_url = this.clip.thumb_url;
        this.clip$.next(this.clip);
        this.getWsIcon();
        console.log(this.clip);
        this.setInit();
      },
      error: (err) => this.setError(err)
    });
  };

  setInitParams() {
    this.loader = true;
    this.params = this.route.snapshot.queryParams as QueryParams;
    this.languageService.setTranslate(this.params.lang);
    this.mixpanelService.init(this.params.email);
    setTimeout(() => {
      if (this.loader) this.snackBar.advice(this.translateService.instant('loadingSlow'));
    }, 5000);
  }

  setError(err: any) {
    this.mixpanelService.track('Landing Error', err);
    switch(err.status) {
      case 403: this.error = 'not_found'; break;
      case 404: this.error = 'bad_request'; break;
      default: this.error = 'error';
    }
    this.setInit();
  }

  setInit() {
    this.loader = false;
    if (!this.error) this.snackBar.close();
    this.languageService.currentLang = this.translateService.currentLang;
  }

  getWsIcon() {
    this.http.get(environment.URL_WORKSPACES + 'landing/logo/'
      + (this.params.new_workspace_id || this.params.workspace_id)).subscribe({
        next: (res: any) => {
          this.clip.workspace_icon = res;
        },
        error: (err) => console.log(err)
      });
  }

  getHeader(){
    let headers = new HttpHeaders();
    return headers
      .set('content-type', 'application/json')
      .set('X-Api-Key', environment.WORKSPACE_KEY)
      .set('Accept', 'application/json');
  }

}
